import $ from "jquery";
export const scrollNav = (page) => {
  console.log("page" + page);
  return $("html, body").animate(
    {
      scrollTop: $("#" + page).offset().top - 100,
    },
    "slow"
  );
};
