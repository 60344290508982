import React, { Component } from "react";
import Header from "../../components/header/Header";
import "./NewsRoomHome.css";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Footer from "../../components/footer/Footer";
import { connect } from "react-redux";

class NewsRoomHome extends Component {
  componentDidMount() {
    $(".menu-container:nth-child(5)").addClass("activeRoute");
  }
  componentDidUpdate(prevProps, prevState) {
    $(".menu-container:nth-child(5)").addClass("activeRoute");
  }
  render() {
    const NewsCard = (data) => {
      return (
        <div className="newsCard">
          <img src={data.image} alt="" />
          <div className="header">{data.header}</div>
          <div className="description">
            {data.description.map((item, index) => {
              if (index === 0) {
                return item.substring(0, 120);
              } else return "";
            })}
            ...
          </div>
          <div className="read">
            <p>Article By : {data.articleBy}</p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => this.props.history.push("/news/" + data.link)}
            >
              Read More
            </p>
          </div>
        </div>
      );
    };
    return (
      <div className="newsHome">
        <Helmet>
          <title>News Room | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co.,blog,news, university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        <div className="cards">
          {this.props.news.map((item, index) => {
            return (
              <NewsCard
                key={index + 11}
                header={item.header}
                image={item.image}
                articleBy={item.articleBy}
                link={item.link}
                description={item.description}
              />
            );
          })}
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    news: state.news,
    activeNews: state.activeNews,
    nextNews: state.nextNews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewsName: (name) => dispatch({ type: "setNewsName", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsRoomHome);
