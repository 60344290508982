import React, { Component } from "react";
import "./footer.css";
import facebook from "../images/white-facebook.png";
// import twitter from "../images/white-twitter.png";
import instagram from "../images/white-instagram.png";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer-container">
        <div className="left">
          <p className="head">CONTACT US</p>
          <div className="desc">
            <p>
              <a href="tel:+91 9820511550">+91 9820511550</a>
            </p>
            <p className="divider">/</p>
            <p>
              <a href="tel:+91 9819711660">+91 9819711660</a>
            </p>
          </div>
          {/* <p className="desc">info@collegeguidancecompany.com</p>
          <p className="desc">info@collegeguidancecompany.com</p> */}
          <a href="mailto:alisha@thecollegeguidanceco.com">
            <p className="desc">alisha@thecollegeguidanceco.com</p>
          </a>
          <a href="mailto:alina@thecollegeguidanceco.com">
            <p className="desc">alina@thecollegeguidanceco.com</p>
          </a>
        </div>
        <div className="center">
          <NavLink activeClassName="activeRoute" to="/about">
            <p>About us</p>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/services">
            <p>Services</p>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/testimonials">
            <p>Testimonials</p>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/news">
            <p>News-Room</p>
          </NavLink>
          <NavLink activeClassName="activeRoute" to="/contact">
            <p>Contact-Us</p>
          </NavLink>
        </div>
        <div className="right">
          <a href="https://www.facebook.com/The-College-Guidance-Co-103283531366972">
            <img src={facebook} alt="" />
          </a>
          <a href="https://www.instagram.com/thecollegeguidanceco/">
            <img src={instagram} alt="" />
          </a>
          {/* <a href="#">
            <img src={instagram} alt="" />
          </a> */}
        </div>
      </div>
    );
  }
}

export default Footer;
