import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../pages/home/Home";
import "../components/common/common.css";
import Contact from "../pages/contact/Contact";
import About from "../pages/about/About";
import Services from "../pages/services/Services";
import Testimonials from "../pages/testimonial/Testimonials";
import NewsRoom from "../pages/newsroom/NewsRoom";
import Register from "../pages/register/Register";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
import NewsRoomHome from "../pages/newsroomHome/NewsRoomHome";
class Main extends Component {
  state = {};
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} exact />
          <Route path="/services" component={Services} exact />
          <Route path="/testimonials" component={Testimonials} exact />
          {/* <Route path="/newsroom" component={NewsRoom} exact /> */}
          <Route path="/contact" component={Contact} exact />
          <Route path="/news" component={NewsRoomHome} exact />
          <Route
            path="/news/:news"
            exact
            render={({ match }) => {
              return <NewsRoom activeeNews={match.params.news} />;
            }}
          />
          <Route path="/register/boston" component={Register} exact />
          <Route path="*" component={PageNotFound} exact />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Main;
