import React, { Component } from "react";
import "./services.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { connect } from "react-redux";
import service1 from "../../components/images/service1.png";
import service2 from "../../components/images/service2.png";
import service3 from "../../components/images/service3.png";
import service4 from "../../components/images/service4.png";
import service5 from "../../components/images/service5.png";
import service6 from "../../components/images/service6.png";
import service7 from "../../components/images/service7.png";
import service8 from "../../components/images/service8.png";
import service9 from "../../components/images/service9.png";
import service10 from "../../components/images/service10.png";
import service11 from "../../components/images/service11.png";
import Service from "../../components/service/Service";
import GetInTouch from "../../components/getintouch/GetInTouch";
import { Helmet } from "react-helmet";

class Services extends Component {
  state = {
    services: [
      {
        img: service1,
        text: "Profile Building",
        desc: "Help students identify areas of interest and strength and personalise a plan for extracurricular activities to ensure a strong and holistic application.",
      },
      {
        img: service2,
        text: "Identifying potential career choices",
        desc: "We get to know every student and then provide personalised guidance on suitable courses and countries, giving direction to the college application process.",
      },
      {
        img: service3,
        text: "Summer School Guidance",
        desc: "We recommend summer programs for students and assist with the application essays and forms.",
      },
      {
        img: service4,
        text: "University List",
        desc: "Create a comprehensive list of universities, keeping in mind the student's profile, interests and personality.",
      },
      {
        img: service5,
        text: "Planning Campus Visits",
        desc: "Create a campus visit itinerary for students and parents who would like to visit prospective universities before applying.",
      },
      {
        img: service6,
        text: "University Application Forms",
        desc: "We provide detailed assistance and guidance when filling out application forms for different countries and systems.",
      },
      {
        img: service7,
        text: "University Application Essays",
        desc: "We help with ideas, topic selection, structure, feedback, editing and refining of all application essays and personal statements.",
      },
      {
        img: service8,
        text: "SAT/ACT Guidance",
        desc: "Share advice and guidance on how to best prepare for standardised testing. We also create test taking timelines for students.",
      },
      {
        img: service9,
        text: "Interview preperation",
        desc: "Conduct mock interviews with students. Guide them on how to best prepare for interviews.",
      },
      {
        img: service10,
        text: "Resume Writing",
        desc: "Help  to create a strong resume that reflects all their achievements and can be submitted to universities as a part of the application.",
      },
      {
        img: service11,
        text: "Guidance and assistance with additional documentation",
        desc: "We guide parents on how to prepare financial and other required documents that supplement university applications.",
      },
    ],
  };
  componentDidMount() {
    this.props.btnClick("");
  }

  render() {
    return (
      <div className="services-container">
        <Helmet>
          <title>Our Services | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co., university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        <div className="services-header animated fadeInDown animation">
          <p className="header">Our Services</p>
          <p className="header-text">
            We provide guidance for undergraduate university admissions across
            all countries. We help you select colleges and courses to apply to,
            and then mentor you through the entire application process, right
            from understanding admission requirements to fulfilling and
            excelling at all of them.
          </p>
          <p className="header-text">
            We start working with students grade 8 onwards. You can sign up for
            the various programmes we offer, which will provide you with
            holistic guidance, alternatively, for a specific query you can meet
            us for a single, one on one appointment.
          </p>
        </div>
        <div className="services-section">
          {this.state.services.map((item, index) => {
            return (
              <Service
                key={15 + index}
                image={item.img}
                text={item.text}
                desc={item.desc}
                className="adjust-service animated fadeInDown animation"
              />
            );
          })}
        </div>
        <GetInTouch />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextpage: (name) => dispatch({ type: "display_info", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
