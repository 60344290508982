import React, { Component } from "react";
import "./testimonial.css";
import uniLogo from "../../components/images/uni-logo.png";
import uniLogo2 from "../../components/images/nu.png";
import uniLogo3 from "../../components/images/qm.jpg";
import uniLogo4 from "../../components/images/lcoa.jpg";
import uniLogo5 from "../../components/images/exeter.png";
import uniLogo6 from "../../components/images/nyu.jpg";
import uniLogo7 from "../../components/images/georgetown.png";
import uniLogo8 from "../../components/images/neast.png";
import babson from "../../components/images/babson.jpeg";
import pratt from "../../components/images/pratt.jpeg";
import boston from "../../components/images/boston.jpeg";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Testimonial from "../../components/testimonial/Testimonial";
import $ from "jquery";
import GetInTouch from "../../components/getintouch/GetInTouch";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
class Testimonials extends Component {
  state = {
    testimonials: [
      {
        words: [
          "Alisha and Alina did a fantastic job counselling and guiding my daughter Ariana through grade 11 and 12 from B.D. Somani International School. I think I was one of their first few clients to have signed up with The College Guidance Co. I had seen Alisha and Alina effortlessly work as BD school counselors and deal with 60 plus kids each school year. Though they both were so young in this particular field where experience and years matter, they knew their job inside out.",
          "The minute I knew they were leaving BD to start their own firm I actually chose them over all others because I saw that these two young girls had the passion and drive that I was looking for, for my daughter Ariana. I needed someone who would be available easily. Someone who Ariana and we as parents could communicate with openly.  I needed someone who would challenge Ariana into giving her essay the best shot there was. I needed someone to give us a game plan on how to work our way to our dream school NYU and achieve it. I needed someone who had in-depth knowledge of the schools that were the right fit for our daughter. And surely Alina and Alisha delivered and went beyond what I as a parent and Ariana as a student needed.",
          "They were patient and calm yet certain and pro active on how to achieve what we all were after. They met Ariana couple of times and then the pandemic hit. Yet they were consistent with online meetings and achieved all goals as per the admission deadlines.",
          "More than just counselors, Alisha and Alina were like older sisters to Ariana and a guiding force. All three worked together as a team. I am so glad I chose The College Guidance Co. to help Ariana with the college process and it did pay off. Ariana did get into NYU which was her Early Decision school, all thanks to the guidance from Alisha and Alina. Good Luck to you both.",
        ],
        name: "Nishi Shah",
        parent: "Parent of Ariana Shah",
        logo: uniLogo6,
        university: "New York University, Class of 2025",
      },
      {
        words: [
          "Over the 2 years that Alisha and Alina guided me on my application, they became my best friends who understood what I genuinely wanted, and curated a plan for me to achieve my dream. Their knowledge, approachability, and constant support pushed me to do the best I could. I remember calling Alisha and Alina stressed and crying after I got waitlisted from one of my top choices, and their resilient attitude coupled with their understanding nature consoled me and pushed me to do better and keep working towards my dream.",
          "They know how to deal with a student’s personality and not just their portfolio. I think every high school student needs to find a friend in their counsellor to truly get what they want out of college and be happy there - Alisha and Alina are just that, I know I can always turn to them for advice. They left no stone unturned to make sure that I get into my fist choice- NYU! They truly know how to make a daunting process fun and simple❤️",
        ],
        name: "Ariana Shah",
        parent: "",
        logo: uniLogo6,
        university: "New York University, Class of 2025",
      },
      {
        words: [
          "At one point of time I was no different than the other parents who wanted to send their child abroad for higher studies. We did not know which subjects to select or how to make the portfolio look promising to stand out in the crowd. I was anxious and completely clueless. My son, Param wasn't sure either on how to go ahead. ",
          "I knew Alisha & Alina since the time they were counselors at B.D. Somani International School. I always wanted someone who is approachable, who helps my child understand his capabilities and who would stand on his head to get the work done and in the right way. Alisha & Alina have been exactly what I have been looking for. They are so approachable and guided Param on each and every step where he thought he couldn't do it or didn't know how to do it.",
          "As we all know by now that IB is frustrating and overloaded with the quantity of work, however, they have helped Param manage this. They have been such a great support to my child as well as to me. I am grateful to them that today my son is so happy with his acceptance in Northeastern University which was his 1st choice University for Music.",
          "Thank you Alisha & Alina for being there.",
        ],
        name: "Bijal Sheth",
        parent: "Parent of Param Sheth",
        logo: uniLogo8,
        university: "Northeastern University, Class of 2025",
      },
      {
        words: [
          "University application can seem daunting \n and overwhelming, especially since students have so much else going on during the process. However, Alisha and Alina made this very busy time so much easier by spacing out the application process. This allowed me to produce the best possible version of my applications well within the deadlines. While creating my university list, they put in a lot of time and effort into ensuring that I was well-informed about all my options so I could make an informed decision. They also made an effort to get to know me as a person which gave them a better understanding of which universities could be good options for me. Their enthusiasm and attention to detail helped me produce a comprehensive and refined application and never failed to motive me! Their experience and knowledge of what universities look for in applicants were very advantageous while writing essays.",
          "Initially, I wasn’t too sure about how to approach these essays. However, Alisha and Alina were very supportive and helped me work through several drafts until we ended up with the best possible work. They helped me edit and refine my work while keeping my voice intact. I can’t imagine going through this process with anyone other than Alisha and Alina. Their guidance was like no other and they always went an extra mile to ensure I would be fully content with the university I end up going to. Thanks to their exceptional service, I’ve been admitted to Northeastern University’s class of 2025, which I believe is a wonderful fit for me!",
        ],
        name: "Pia Chandrakant",
        parent: "",
        logo: uniLogo8,
        university: "Northeastern University, Class of 2025",
      },
      {
        words: [
          "University applications are arguably one of the most stressful aspects of our college career. At the beginning of my IB diploma program, I was overcome with confusion and completely overwhelmed during lectures pertaining to university. However, as soon as I started meeting my university guidance counsellors Ms Alisha and Ms Alina, I felt like I was gaining some form of direction. They have helped me see things in a new light and have always believed in me as a person. I felt so much stronger and more confident after meeting with them which motivated me into building my application. From the very first session I found them extremely forthcoming and easy to talk to. Through the course of the two years they made sure all of my concerns were addressed.",
          "There were so many times where in the process became physically and emotionally draining but they have always helped me navigate through such times. They were always willing to put in as much effort to make sure my application was the strongest. We have re-worked and drafted so many essays, and they have always given me the most valuable perspectives and feedback on my essays. More than working with me closely to perfect the essays, they put a lot of time and effort into knowing me as a person and therefore would always know which university is the perfect fit for me. They were super well-informed about universities and have guided me incredibly as I chose the colleges that I wished to attend. Today, I have gotten into some of the top universities in the UK and US, something which I did not thing was possible at all. I owe a big part of this success to my counsellors. They have guided me so beautifully and I will always be grateful for them. Not only because of their help pertaining to my universities but for being such an incredible support system over the years.",
        ],
        name: "Diyaa Udani",
        parent: "",
        logo: uniLogo6,
        university: "New York University",
      },
      {
        words: [
          "I have always dreamt of studying at a top university but often felt lost and overwhelmed about the application process till I met Ms Alisha. This journey wasn't an easy one but was made easy because of Ms Alisha's support , guidance and constant reassurance. Transitioning between schools could not have been easier if it were not for her approachable nature which made me feel instantly connected and comfortable. She recognised my strengths and encouraged me to apply to the top universities in the US. When I struggled to depict myself through college essays, she mentored and guided me and we worked closely through multiple drafts.",
          "Ms Alisha did not leave a stone unturned when it came to strengthening my application, shaping my resume and supporting it with strong recommendation letters. She invested so much time and patience in me which has helped shape me into who I am and will be one day. I am eternally grateful to her and she will always be my mentor, guide and friend. ",
        ],
        name: "Maanya Choksey",
        parent: "",
        logo: uniLogo7,
        university: "Georgetown University",
      },
      {
        words: [
          "I would like to thank Alisha Israni for her guidance counseling help with regards to both my daughters at B. D. Somani International School. Choosing which college to go to, what programs to apply to and which activities to put your energy in are all very daunting choices when your children are already under pressure to excel in the academic portion of the IB Diploma. I think for my children these choices were made easier by brainstorming with Alisha. She provided them with information in a timely and unbiased manner. She also gave them constructive feedback and supported them in their choices. She is very approachable and is always patient with incessant questioning from me and my children. I am grateful for all her help and very proud and glad to say that both my children got into their early decision choices with her help.",
        ],
        name: "Vaishali Colin Shah",
        parent: "Parent of Simran Colin Shah and Riya Colin Shah ",
        logo: uniLogo,
        university: "Barnard College",
      },
      {
        words: [
          "Alisha has advised my daughter, Samira Bulchandani since the 9th grade. She is an excellent university guidance counselor and it has been an absolute pleasure to work with her. Alisha is  experienced, affable, accessible and knowledgable. She encouraged Samira to explore a diverse range of colleges recognizing her potential and motivated her to apply to top notch colleges that my daughter had not considered for herself.",
          "The entire US college application process can be a daunting one, however, Alisha made it seamless and stress free . Her strong personal recommendation letter, to giving prompt feedback on essays and uploading all external recommendation letters and documents ensured a thorough and strong winning application.",
          "Samira and I are eternally grateful for all her support and motivation, and handling all our frantic demands in a such calm and gentle manner. I strongly recommend her counseling services and am confident she will help many to achieve their dreams.",
        ],
        name: "Bonita Bulchandani",
        parent: "Parent of Samira Bulchandani",
        logo: uniLogo2,
        university: "Northwestern University",
      },
      {
        words: [
          "Alisha put in an immense amount of work to understand my requirements and helped me strategically plan my application. She would effectively respond to any doubts or questions I had. She helped me refine my personal statement and provided excellent counsel. Alisha simplified the daunting process of applying to university and made it enjoyable and gratifying. I would highly recommend counting on her experience and guidance through the application process.",
        ],
        name: "Zahir Tapia",
        parent: "",
        logo: uniLogo3,
        university: "QueenMary University of London",
      },
      {
        words: [
          "My daughter Tanushree Agarwal was a student of B.D.Somani International School. She is presently studying in the University of Exeter,UK. We have had a long association with Ms. Alisha. She has gone out of her way to help us decide the University for Tanushree. We applied to Universities through UCAS and got a conditional offer from them. But after the results we realised that we needed to apply in Clearing. Till the very last minute she was always available at any given day/time. I was very disturbed but the way she went about her work is commendable. I have a younger son who is still in the 9th in B.D.Somani International School and I am sure to come back to her. I highly recommend parents/children to join Ms Alisha for the application. With my experience I know for sure she will do anything to ensure the child gets the right University. Thank you for everything Ms. Alisha.",
        ],
        name: "Shraddha Agarwal",
        parent: "Parent of Tanushree Agarwal",
        logo: uniLogo5,
        university: "University of Exeter",
      },
      {
        words: [
          "Success is a combination of hearts that direct on to their goals. I have had a similar heart that drove this dream and ignited the fire in me that I am being enlightened in my career path. Ms. Alisha Israni has worked with me since the 9th grade and has always helped me in any way possible. I still recall my first meeting with her and I remember telling her my interests about applying to an art school. The first thing Ms. Alisha told me was to get some exposure in this field by doing a summer school. By taking her suggestion, I attended two summer schools (ISDI Parsons and SAIC). I could not have thanked her enough for encouraging me to uptake these courses, as it widened my perspective about how art universities would seem like.",
          "More than a Counsellor, she is a very supportive and approachable person. She had constantly been in touch with me and aided me whenever I felt hesitant, which I appreciated. I remember going to her office multiple times for taking advice about both - my common app essay and personal statement. She had willingly edited them for me and gave me feedback, which I incorporated constructively. Before sending out my applications, Ms. Alisha double checked whether everything was alright. I have always felt comfortable with her and I could be honest and express myself about how I felt before my applications were sent out. ",
          "Ms. Alisha has been a very cooperative mentor and has played a large role in my decision making, as she is extremely supportive. I was able to build a trustworthy relationship with her and I can proudly say that she was one of my favourite teachers. I still know for a fact that if I am ever in doubt about any decision making and if I need her guidance, she will still help me! It was a pleasure to work with her.",
        ],
        name: "Anushka Mehta",
        parent: "",
        logo: uniLogo4,
        university: "London College of Fashion, University of the Arts London",
      },
      {
        words: [
          "I spent a majority of my ninth grade searching for college counsellors, I met atleast 8 counsellors before I found The College Guidance Co. In the first meeting itself Alisha’s open mindedness and clarity made me instantly realise that they were the counsellors I wanted to go with and I am glad to say, that now, I undoubtedly made the right choice. The teamwork and coordination between Alisha and Alina is what made the last 2-3 years so easy, whether it was last minute edits to my applications or the most random question in regards to my university choices, I got nothing short of extreme patience and much needed bluntness from them both. I remember there were times where I called Alisha at the oddest of hours with queries and almost every single time she would pick up and guide me on how I can find a solution for it. But what stood out for me was Alisha’s involvement with my art portfolio, the way she pushed me and ensured that not only my essays were on time and had gone through enough editting but also that my artworks were on track and worked to perfection. She knew me better than I did and that is what gave me immense support through the entire application process.", 
            "Alisha and Alina were ceaselessly amazing when it came to helping me stay on top of my application, my portfolio, my academics, building my resumé and even finding me internships!There is no one better I could have asked for to guide me through the entire process and I can proudly say that because of them I got into 8 out of 9 of my universities out of which I even got scholarships from SAIC and Parsons! I swear by this dream team and I am so grateful for them.",
        ],
        name: "Aarna Dalal",
        parent: "",
        logo: pratt,
        university: "Dhirubhai Ambani International School, Class of 2022, Pratt Institute",
      },
      {
        words: [
          "I had contacted The College Guidance Co. in September which was very close to several university deadlines and I was  extremely stressed however this changed as soon as I spoke to them. After several essay corrections and a lot of back and forth, they dealt with everything and sorted everything out. I really appreciate the effort and time they invested in my applications. I was surprised to see that I got into almost all the universities I applied to and the credit only goes to them.They were exceptionally helpful and if it weren’t for them I would not have had such a seamless and successful college application process. I will be forever grateful and thankful for them.",
        ],
        name: "Ishaan Parikh",
        parent: "",
        logo: boston,
        university: "BD Somani International School, Class of 2022, Boston University",
      },
      {
        words: [
          "Over the past 2 years, Alina and Alisha have been the best help I could possibly seek for my college admissions process. One of their biggest contributions was understanding my potential and accordingly planning the various colleges that would suit me. At the start of this journey, I was completely clueless as to what was to be done. But, with their guidance, it didnt take me long to figure out how to write essays in a way that would reflect my personality. Another quality that I truly admire is Alina’s desire for perfection, since it would take at least a couple drafts until I could get her approval. I would like to thank them for their time and faith in me. ",
        ],
        name: "Vishwaroop Chhabria",
        parent: "",
        logo: babson,
        university: "Dhirubhai Ambani International School, Class of 2022, Babson College",
      },
    ],
  };
  componentDidMount() {
    $(window).scrollTop(0);
    this.props.btnClick("");
  }
  render() {
    return (
      <div className="testimonials-container">
        <Helmet>
          <title>Testimonials | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co., university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        {/* <div className="map-container">
          <div className="left-section">
            <div className="header">Contry's our students are present in</div>
            <div className="sub-header">Hover to see locations</div>
            <div className="mob-sub-header">Click to see locations</div>
          </div>
          <div className="right-section">
            <div
              class="chart"
              id="regions_div"
              style={$(window).width() > 800 ? DeskStyle : MobStyle}
            ></div>
             
          </div>
        </div> */}
        <div className="testimonials">
          {this.state.testimonials.map((item, index) => {
            return (
              <Testimonial
                key={56 + index}
                logo={item.logo}
                words={item.words}
                parentName={item.name}
                parentOf={item.parent}
                university={item.university}
              />
            );
          })}
        </div>
        <GetInTouch />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextpage: (name) => dispatch({ type: "display_info", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
