import React, { Component } from "react";
import "./service.css";
import $ from "jquery";
class Service extends Component {
  state = {};
  componentDidMount() {
    $(window).scrollTop(0);
  }

  render() {
    return (
      <div
        ref={this.props.ref}
        className={
          this.props.className
            ? "service-container " + this.props.className
            : "service-container"
        }
      >
        <div className="img-container">
          <img
            src={this.props.image}
            alt=""
            // className="animated fadeInDown animation"
          />
        </div>
        <div className="text">{this.props.text}</div>
        {this.props.desc && (
          <div className="description">{this.props.desc}</div>
        )}
      </div>
    );
  }
}

export default Service;
