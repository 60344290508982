import React, { Component } from "react";
import "./about.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import arrow from "../../components/images/arrow.png";
// import Alisha from "../../components/images/Alisha.png";
// import Aliya from "../../components/images/Aliya.png";
// import readMore from "../../components/images/readMore.png";
import { connect } from "react-redux";
import $ from "jquery";
import background from "../../components/images/about-banner/background.png";
import mobbackground from "../../components/images/about-banner/mob-background.png";
import chair from "../../components/images/about-banner/chair.png";
import drawer from "../../components/images/about-banner/drawer.png";
import floor from "../../components/images/about-banner/floor.png";
import pc3 from "../../components/images/about-banner/pc3.png";
import pc4 from "../../components/images/about-banner/pc4.png";
import table from "../../components/images/about-banner/table.png";
import vase from "../../components/images/about-banner/vase.png";
import flower from "../../components/images/about-banner/flower.png";
import woman4 from "../../components/images/about-banner/women4.png";
import woman5 from "../../components/images/about-banner/women5.png";
import { scrollNav } from "../../components/reducer/script";
import { Helmet } from "react-helmet";
var scrolll = 10;

class About extends Component {
  state = {
    founder: [
      {
        name: "Alisha Israni",
        post: "Founder",
        description: [
          "The College Guidance Co. was founded by Alisha Israni in 2020. Alisha has been a university guidance counselor at B.D. Somani International School for 6 years, out of which she headed the department for 3 years. Alisha has mentored over 400 students with their college applications. She has experience working with students right from grade 8 up to grade 12. During her time at BD, Alisha guided students with where to apply, how to apply, assisting them with the student’s part of the application, but also carrying out the school’s bit, which has given her tremendous insight into transcripts, letters of recommendation, and teacher and counsellor forms on various application portals. This experience has allowed Alisha to have access to, and explore the role of a high school in a student’s application and how that can be strengthened.",
          "Alisha has interacted with representatives from prestigious institutions such as Princeton University, Columbia University, The University of Chicago, Northwestern University, Georgetown University, University of Pennsylvania, Barnard College, University of Cambridge, New York University, University College London, and Yale University to name a few. Over the years, she has guided students who have been admitted to the schools listed above, among others. She has been a part of panel discussions and mock admissions committees to gain a deep understanding of what these institutions look for in prospective applicants. Having access to this kind of information, first hand, has given Alisha a deep level of insight into college admissions from a university’s perspective.",
          "Alisha graduated from the University of Nottingham in 2014, prior to which she was a student at BD Somani International School and the JB Petit High School for Girls. This experience also helps her understand the transition first from the ICSE to the IB and then to College, from the perspective of a student.",
        ],
      },
      {
        name: "Alina Israni",
        post: "Co-founder",
        description: [
          "Alina Israni worked at BD Somani International School for a year, during which she helped students with their university applications. She is passionate about writing and enjoys guiding students with their essays, ensuring they are able to present the best version of themselves. Prior to working at BD Somani International School, Alina informally helped students amongst her family and friends with their application essays, discovering that she had Alina’s strength lies in identifying a student’s interests and attributes and then guiding them on how to develop them further, in order to build a strong extra-curricular profile that provides universities with a compelling story for every application. ",
          "Alina also graduated from the University of Nottingham in 2014, prior to which she studied the IB Curriculum at BD Somani International School and the ICSE at the JB Petit High School for Girls.",
          "Together, as a team, Alisha and Alina employ one another’s strengths to ensure holistic, specialised and comprehensive mentoring throughout the college application process.",
        ],
      },
    ],
    activeFounder: "Alisha Israni",
  };
  founderClick = (name) => {
    this.setState({ activeFounder: name });
  };
  componentDidMount() {
    // $(window).scrollTop(0);

    if (this.props.id) {
      scrollNav(this.props.id);
    } else {
      $(window).scrollTop(0);
    }
    // $(".image1 .overlay").css("display", "none");
    $(".imageContainer").click(function () {
      $(".hide").removeClass("hide");
      $(this).addClass("hide");
    });
    $(window).scroll(function () {
      scrolll = $(window).scrollTop();

      if (scrolll > 590) {
        $(".image-section").addClass("animated fadeIn animation");
        $(".info-section").addClass("animated fadeIn animation");
      }
      // else $(".image-section").removeClass("animated fadeIn animation");
    });

    setTimeout(function () {
      $(".floor").removeClass("bottomHide");
      $(".chair").removeClass("rightHide");
      $(".drawer").removeClass("leftHide");
      $(".vase").removeClass("topHide");
      $(".flower").removeClass("topHide");
      $(".table").removeClass("opacity");
      $(".pc3").removeClass("topHide");
      $(".pc4").removeClass("topHide");
      $(".woman4").removeClass("leftHide");
      $(".woman5").removeClass("rightHide");
    }, 10);

    $(".readMore").click(function () {
      $(".hidden-info").css("display", "block");
      $(".readMore").css("display", "none");
      $(".readLess").css("display", "flex");
    });
    $(".readLess").click(function () {
      $(".hidden-info").css("display", "none");
      $(".readMore").css("display", "flex");
      $(".readLess").css("display", "none");
    });
    $(".readMore2").click(function () {
      $(".hidden-info2").css("display", "block");
      $(".readMore2").css("display", "none");
      $(".readLess2").css("display", "flex");
    });
    $(".readLess2").click(function () {
      $(".hidden-info2").css("display", "none");
      $(".readMore2").css("display", "flex");
      $(".readLess2").css("display", "none");
    });
  }

  render() {
    return (
      <div className="about-container">
        <Helmet>
          <title>About Us | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co., university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        <div className="about-banner">
          <div className="left-section animated fadeIn animation">
            <div className="header">About Us</div>
            <div className="sub-header">Vision and Mission</div>
            <div className="description">
              <p>
                The College Guidance Co. provides a boutique university guidance
                service, helping students throughout the process, right from
                building a strong profile, to shortlisting universities, essay
                and personal statement writing, putting in a strong application,
                and then selecting which school to finally attend. We also offer
                specialised services for students who need guidance with a
                particular aspect of the university application process.
              </p>
              <p>
                We choose to work with a limited number of students and get to
                know each one of them well so that our guidance can be most
                effective. Our goal is to share our expertise, in a way that
                most benefits students and parents. Keeping this in mind, we
                personally mentor every student and each individual case is
                handled by us. Personal attention and mentorship is what sets
                our program apart.
              </p>
              <p>
                At The College Guidance Co., we value and understand that each
                student has unique interests and goals, and we take pride in
                personalizing the guidance we give to fit those needs. Our aim
                is to simplify this process, which can be overwhelming for
                students and parents alike, and make it an enjoyable and
                enriching one.
              </p>
            </div>
          </div>
          <div className="right-section">
            <img
              src={background}
              alt=""
              className="background animated fadeIn animation"
            />
            <img
              src={mobbackground}
              alt=""
              className="mobile-background animated fadeIn animation"
            />
            <img src={floor} alt="" className="floor bottomHide" />
            <img src={chair} alt="" className="chair rightHide" />
            <img src={drawer} alt="" className="drawer leftHide" />
            <img src={vase} alt="" className="vase topHide" />
            <img src={flower} alt="" className="flower topHide" />
            <img src={table} alt="" className="table opacity" />
            <img src={pc3} alt="" className="pc4 topHide" />
            <img src={pc4} alt="" className="pc3 topHide" />
            <img src={woman4} alt="" className="woman4 leftHide" />
            <img src={woman5} alt="" className="woman5 rightHide" />
          </div>
          <div className="meet-the-team">
            <p>Meet the team</p>
            <img src={arrow} alt="" className="downArrow" />
          </div>
        </div>

        <div className="founder-container" id="founders">
          {/* <div className="image-section">
              <div
                className="image1 imageContainer hide"
                onClick={() => this.founderClick("Alisha Israni")}
              >
                <img src={Alisha} alt="" />
                <div className="overlay"></div>
              </div>
              <div
                className="image2 imageContainer"
                onClick={() => this.founderClick("Alina Israni")}
              >
                <img src={Aliya} alt="" />
                <div className="overlay"></div>
              </div>
            </div> */}
          <div className="info-section">
            <div className="founder-names">
              <div
                className={
                  this.state.activeFounder === "Alisha Israni"
                    ? "founder"
                    : "founder inactiveFounder"
                }
                onClick={() => this.founderClick("Alisha Israni")}
              >
                <div className="founderName animated fadeIn animation">
                  Alisha Israni
                </div>
                <div className="founderPost animated fadeIn animation">
                  Founder
                </div>
              </div>
              <div
                className={
                  this.state.activeFounder === "Alina Israni"
                    ? "founder"
                    : "founder inactiveFounder"
                }
                onClick={() => this.founderClick("Alina Israni")}
              >
                <div className="founderName animated fadeIn animation">
                  Alina Israni
                </div>
                <div className="founderPost animated fadeIn animation">
                  Co-founder
                </div>
              </div>
            </div>
            {this.state.founder.map((item, index) => {
              if (this.state.activeFounder === item.name) {
                return (
                  <React.Fragment key={index + 94}>
                    <div className="description animated fadeIn animation">
                      {item.description.map((desc, index) => {
                        return <p key={index + 87}>{desc}</p>;
                      })}
                    </div>
                  </React.Fragment>
                );
              }
              return null;
            })}
          </div>
        </div>

        {/* {$(window).width() < 480 && (
          <div className="mobile-founder-container">
            <div className="header">Meet the Team</div>
            <div className="founders">
              <img src={Alisha} alt="" className="founder-image" />
              <div className="founder-name">Alisha Israni</div>
              <div className="founder-post">Co-founder</div>
              <div className="info-container">
                The College Guidance Co. was founded by Alisha Israni in 2020.
                Alisha has been a university guidance counselor at B.D. Somani
                International School for 6 years, out of which she headed the
                department for 3 years. Alisha has mentored over 400 students
                with their college applications. She has experience working with
                students right from grade 8 up to grade 12.
                <div className="hidden-info">
                  During her time at BD, Alisha guided students with where to
                  apply, how to apply, assisting them with the student’s part of
                  the application, but also carrying out the school’s bit, which
                  has given her tremendous insight into transcripts, letters of
                  recommendation, and teacher and counsellor forms on various
                  application portals. This experience has allowed Alisha to
                  have access to, and explore the role of a high school in a
                  student’s application and how that can be strengthened. Alisha
                  has interacted with representatives from prestigious
                  institutions such as Princeton University, Columbia
                  University, The University of Chicago, Northwestern
                  University, Georgetown University, University of Pennsylvania,
                  Barnard College, University of Cambridge, New York University,
                  University College London, and Yale University to name a few.
                  Over the years, she has guided students who have been admitted
                  to the schools listed above, among others. She has been a part
                  of panel discussions and mock admissions committees to gain a
                  deep understanding of what these institutions look for in
                  prospective applicants. Having access to this kind of
                  information, first hand, has given Alisha a deep level of
                  insight into college admissions from a university’s
                  perspective. Alisha graduated from the University of
                  Nottingham in 2014, prior to which she was a student at BD
                  Somani International School and the JB Petit High School for
                  Girls. This experience also helps her understand the
                  transition first from the ICSE to the IB and then to College,
                  from the perspective of a student.
                </div>
                <div className="readMore">
                  <p>Read More</p>
                  <img src={readMore} alt="" />
                </div>
                <div className="readLess">
                  <p>Read Less</p>
                  <img src={readMore} alt="" />
                </div>
              </div>
            </div>
            <div className="founders">
              <img src={Aliya} alt="" className="founder-image" />
              <div className="founder-name">Alina Israni</div>
              <div className="founder-post">Co-founder</div>
              <div className="info-container">
                Alina Israni worked at BD Somani International School for a year, during which she helped students with their university applications. She is passionate about writing and enjoys guiding students with their essays, ensuring they are able to present the best version of themselves. Prior to working at BD Somani International School, Alina informally helped students amongst her family and friends with their application essays, discovering that she had Alina’s strength lies in identifying a student’s interests and attributes and then guiding them on how to develop them further, in order to build a strong extra-curricular profile that provides universities with a compelling story for every application.
                <div className="hidden-info2">
                  Alina also graduated from the University of Nottingham in 2014, prior to which she studied the IB Curriculum at BD Somani International School and the ICSE at the JB Petit High School for Girls.Together, as a team, Alisha and Alina employ one another’s strengths to ensure holistic, specialised and comprehensive mentoring throughout the college application process.
                </div>
                <div className="readMore2">
                  <p>Read More</p>
                  <img src={readMore} alt="" />
                </div>
                <div className="readLess2">
                  <p>Read Less</p>
                  <img src={readMore} alt="" />
                </div>
              </div>
            </div>
          </div>
        )} */}

        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    id: state.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextpage: (name) => dispatch({ type: "display_info", value: name }),
    btnClick: () => dispatch({ type: "btnClick" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
