import React, { Component } from "react";
import "./home.css";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import Button from "../../components/button/Button";
import cover1 from "../../components/images/banner-image/cover1.png";
import cover2 from "../../components/images/banner-image/cover2.png";
import cover3 from "../../components/images/banner-image/cover3.png";
import cover4 from "../../components/images/banner-image/cover4.png";
import cover5 from "../../components/images/banner-image/cover5.png";
import cover6 from "../../components/images/banner-image/cover6.png";
import book1 from "../../components/images/banner-image/book1.png";
import book2 from "../../components/images/banner-image/book2.png";
import book3 from "../../components/images/banner-image/book3.png";
import book4 from "../../components/images/banner-image/book4.png";
import cap from "../../components/images/banner-image/cap.png";
import degree from "../../components/images/banner-image/degree.png";
import book7 from "../../components/images/banner-image/book7.png";
import book8 from "../../components/images/banner-image/book8.png";
import book9 from "../../components/images/banner-image/book9.png";
import pin from "../../components/images/banner-image/pin.png";

import insta1 from "../../components/images/insta/1.jpeg";
import insta2 from "../../components/images/insta/2.jpeg";
import insta3 from "../../components/images/insta/3.jpeg";
import insta4 from "../../components/images/insta/4.jpeg";
import insta5 from "../../components/images/insta/5.jpeg";
import insta6 from "../../components/images/insta/6.jpeg";

import chair from "../../components/images/about-image/chair.png";
import drawer from "../../components/images/about-image/drawer.png";
import floor from "../../components/images/about-image/floor.png";
import pc1 from "../../components/images/about-image/pc1.png";
import pc2 from "../../components/images/about-image/pc2.png";
import table from "../../components/images/about-image/table.png";
import vase from "../../components/images/about-image/vase.png";
import flower from "../../components/images/about-image/flower.png";
import woman1 from "../../components/images/about-image/women1.png";
import woman2 from "../../components/images/about-image/woman2.png";

import uniLogo from "../../components/images/uni-logo.png";
import uniLogo2 from "../../components/images/nu.png";
import uniLogo3 from "../../components/images/qm.jpg";
// import uniLogo4 from "../../components/images/lcoa.jpg";
import uniLogo5 from "../../components/images/exeter.png";
import uniLogo6 from "../../components/images/georgetown.png";
import uniLogo7 from "../../components/images/nyu.jpg";
import uniLogo8 from "../../components/images/neast.png";

import arrow from "../../components/images/arrow.png";
// import arrow2 from "../../components/images/arrow2.png";
import service1 from "../../components/images/service1.png";
import service2 from "../../components/images/service2.png";
import service3 from "../../components/images/service3.png";
import service4 from "../../components/images/service4.png";
import service5 from "../../components/images/service5.png";
import service6 from "../../components/images/service6.png";
import service7 from "../../components/images/service7.png";
import service8 from "../../components/images/service8.png";
import service9 from "../../components/images/service9.png";
import event1 from "../../components/images/events/event1.jpeg";
import event2 from "../../components/images/events/event2.jpeg";
import event3 from "../../components/images/events/event3.jpeg";

import Slider from "react-slick";
import $ from "jquery";
import Testimonial from "../../components/testimonial/Testimonial";
import Service from "../../components/service/Service";
import { TimelineLite } from "gsap/all";
import Footer from "../../components/footer/Footer";
import { NavLink } from "react-router-dom";
import GetInTouch from "../../components/getintouch/GetInTouch";
import NewsSlide from "../../components/news/NewsSlide";
import { Helmet } from "react-helmet";
var scrolll = 0;
let cards = [];
let tl = new TimelineLite({ paused: true });
class Home extends Component {
  state = {
    message: "Get In Touch",
    testimonials: [
      {
        words: [
          "Alisha and Alina did a fantastic job counselling and guiding my daughter Ariana through grade 11 and 12 from B.D. Somani International School. I think I was one of their first few clients to have signed up with The College Guidance Co. I had seen Alisha and Alina effortlessly work as BD school counselors and deal with 60 plus kids each school year. Though they both were so young in this particular field where experience and years matter, they knew their job inside out.",
          "The minute I knew they were leaving BD to start their own firm I actually chose them over all others because I saw that these two young girls had the passion and drive that I was looking for, for my daughter Ariana. I needed someone who would be available easily. Someone who Ariana and we as parents could communicate with openly.  I needed someone who would challenge Ariana into giving her essay the best shot there was. I needed someone to give us a game plan...",
        ],
        name: "Nishi Shah",
        parent: "Parent of Ariana Shah",
        logo: uniLogo7,
        university: "New York University, Class of 2025",
      },
      {
        words: [
          "Over the 2 years that Alisha and Alina guided me on my application, they became my best friends who understood what I genuinely wanted, and curated a plan for me to achieve my dream. Their knowledge, approachability, and constant support pushed me to do the best I could. I remember calling Alisha and Alina stressed and crying after I got waitlisted from one of my top choices, and their resilient attitude coupled with their understanding nature consoled me and pushed me to do better and keep working towards my dream.",
          "They know how to deal with a student’s personality and not just their portfolio. I think every high school student needs to find a friend in their counsellor to truly get what they want out of college and be happy there - Alisha and Alina are just that, I know I can always turn to them for advice. They left no stone unturned to make sure that I get into my fist choice- NYU! They truly know how to make a daunting process fun and simple❤️",
        ],
        name: "Ariana Shah",
        parent: "",
        logo: uniLogo7,
        university: "New York University, Class of 2025",
      },
      {
        words: [
          "At one point of time I was no different than the other parents who wanted to send their child abroad for higher studies. We did not know which subjects to select or how to make the portfolio look promising to stand out in the crowd. I was anxious and completely clueless. My son, Param wasn't sure either on how to go ahead. ",
          "I knew Alisha & Alina since the time they were counselors at B.D. Somani International School. I always wanted someone who is approachable, who helps my child understand his capabilities and who would stand on his head to get the work done and in the right way. Alisha & Alina have been exactly what I have been looking for. They are so approachable and guided Param on each and every step where he thought he couldn't do it or didn't know how to do it.",
          "As we all know by now that IB is frustrating and overloaded with the quantity of work, however, they have helped Param manage this. They have been such a great support to my child as well...",
        ],
        name: "Bijal Sheth",
        parent: "Parent of Param Sheth",
        logo: uniLogo8,
        university: "Northeastern University, Class of 2025",
      },
      {
        words: [
          "University application can seem daunting and overwhelming, especially since students have so much else going on during the process. However, Alisha and Alina made this very busy time so much easier by spacing out the application process. This allowed me to produce the best possible version of my applications well within the deadlines. While creating my university list, they put in a lot of time and effort into ensuring that I was well-informed about all my options so I could make an informed decision. They also made an effort to get to know me as a person which gave them a better understanding of which universities could be good options for me. Their enthusiasm and attention to detail helped me produce a comprehensive and refined application and never failed to motive me! Their experience and knowledge of what universities look for in applicants were very advantageous while writing essays.",
          "Initially, I wasn’t too sure about how to approach...",
        ],
        name: "Pia Chandrakant",
        parent: "",
        logo: uniLogo8,
        university: "Northeastern University, Class of 2025",
      },
      {
        words: [
          "University applications are arguably one of the most stressful aspects of our college career. At the beginning of my IB diploma program, I was overcome with confusion and completely overwhelmed during lectures pertaining to university. However, as soon as I started meeting my university guidance counsellors Ms Alisha and Ms Alina, I felt like I was gaining some form of direction. They have helped me see things in a new light and have always believed in me as a person. I felt so much stronger and more confident after meeting with them which motivated me into building my application. From the very first session I found them extremely forthcoming and easy to talk to. Through the course of the two years they made sure all of my concerns were addressed.",
          "There were so many times where in the process became physically and emotionally draining but they have always helped me navigate through such times. They were always.....",
        ],
        name: "Diyaa Udani",
        parent: "",
        logo: uniLogo7,
        university: "New York University",
      },
      {
        words: [
          "I have always dreamt of studying at a top university but often felt lost and overwhelmed about the application process till I met Ms Alisha. This journey wasn't an easy one but was made easy because of Ms Alisha's support , guidance and constant reassurance. Transitioning between schools could not have been easier if it were not for her approachable nature which made me feel instantly connected and comfortable. She recognised my strengths and encouraged me to apply to the top universities in the US. When I struggled to depict myself through college essays, she mentored and guided me and we worked closely through multiple drafts.",
          "Ms Alisha did not leave a stone unturned when it came to strengthening my application, shaping my resume and supporting it with strong recommendation letters. She invested so much time and patience in me which has helped shape me into who I am and will be one day. I am eternally grateful to her and she will always be my mentor, guide and friend. ",
        ],
        name: "Maanya Choksey",
        parent: "",
        logo: uniLogo6,
        university: "Georgetown University",
      },
      {
        words: [
          "I would like to thank Alisha Israni for her guidance counseling help with regards to both my daughters at B. D. Somani International School. Choosing which college to go to, what programs to apply to and which activities to put your energy in are all very daunting choices when your children are already under pressure to excel in the academic portion of the IB Diploma. I think for my children these choices were made easier by brainstorming with Alisha. She provided them with information in a timely and unbiased manner. She also gave them constructive feedback and supported them in their choices. She is very approachable and is always patient with incessant questioning from me and my children. I am grateful for all her help and very proud and glad to say that both my children got into their early decision choices with her help.",
        ],
        name: "Vaishali Colin Shah",
        parent: "Parent of Simran Colin Shah and Riya Colin Shah ",
        logo: uniLogo,
        university: "(Barnard College)",
      },
      {
        words: [
          "Alisha has advised my daughter, Samira Bulchandani since the 9th grade. She is an excellent university guidance counselor and it has been an absolute pleasure to work with her. Alisha is  experienced, affable, accessible and knowledgable. She encouraged Samira to explore a diverse range of colleges recognizing her potential and motivated her to apply to top notch colleges that my daughter had not considered for herself.",
          "The entire US college application process can be a daunting one, however, Alisha made it seamless and stress free . Her strong personal recommendation letter, to giving prompt feedback on essays and uploading all external recommendation letters and documents ensured a thorough and strong winning application.",
          "Samira and I are eternally grateful for all her support and motivation, and handling all our frantic demands in a such calm and gentle manner. I strongly recommend her counseling services and am confident she will help many to achieve their dreams.",
        ],
        name: "Bonita Bulchandani",
        parent: "Parent of Samira Bulchandani",
        logo: uniLogo2,
        university: "(Northwestern University)",
      },
      {
        words: [
          "Alisha put in an immense amount of work to understand my requirements and helped me strategically plan my application. She would effectively respond to any doubts or questions I had. She helped me refine my personal statement and provided excellent counsel. Alisha simplified the daunting process of applying to university and made it enjoyable and gratifying. I would highly recommend counting on her experience and guidance through the application process.",
        ],
        name: "Zahir Tapia",
        parent: "",
        logo: uniLogo3,
        university: "(QueenMary University of London)",
      },
      {
        words: [
          "My daughter Tanushree Agarwal was a student of B.D.Somani International School. She is presently studying in the University of Exeter,UK. We have had a long association with Ms. Alisha. She has gone out of her way to help us decide the University for Tanushree. We applied to Universities through UCAS and got a conditional offer from them. But after the results we realised that we needed to apply in Clearing. Till the very last minute she was always available at any given day/time. I was very disturbed but the way she went about her work is commendable. I have a younger son who is still in the 9th in B.D.Somani International School and I am sure to come back to her. I highly recommend parents/children to join Ms Alisha for the application. With my experience I know for sure she will do anything to ensure the child gets the right University. Thank you for everything Ms. Alisha.",
        ],
        name: "Shraddha Agarwal",
        parent: "Parent of Tanushree Agarwal",
        logo: uniLogo5,
        university: "(University of Exeter)",
      },
    ],
    services: [
      {
        img: service1,
        text: "Profile Building",
      },
      {
        img: service2,
        text: "Identifying potential career choices",
      },
      {
        img: service3,
        text: "Summer School Guidance",
      },
      {
        img: service4,
        text: "University List",
      },
      {
        img: service5,
        text: "Planning Campus Visit",
      },
      {
        img: service6,
        text: "University Application Forms",
      },
      {
        img: service7,
        text: "University Application Essays",
      },
      {
        img: service8,
        text: "SAT/ACT Guidance",
      },
      {
        img: service9,
        text: "Interview preperation",
      },
    ],
  };
  next = () => {
    this.slider.slickNext();
  };
  prev = () => {
    this.slider.slickPrev();
  };
  next2 = () => {
    this.slider2.slickNext();
  };
  prev2 = () => {
    this.slider2.slickPrev();
  };

  runAnimation = () => {
    this.tl.play();
  };
  componentDidMount() {
    this.props.btnClick("");
    $(window).scroll(function () {
      scrolll = $(window).scrollTop();
      //   2000
      if (scrolll > 1790) {
        tl.play();
        $(".service-container .img-container img").addClass(
          "animated fadeIn animation"
        );
        $(".custom-service").removeClass("opacityHide");
      }
      if ($(window).width() > 800) {
        if (scrolll > 500) {
          $(".floor").removeClass("bottomHide");
          $(".chair").removeClass("rightHide");
          $(".drawer").removeClass("leftHide");
          $(".vase").removeClass("topHide");
          $(".flower").removeClass("topHide");
          $(".table").removeClass("opacity");
          $(".pc1").removeClass("topHide");
          $(".pc2").removeClass("topHide");
          $(".woman1").removeClass("leftHide");
          $(".woman2").removeClass("rightHide");
        }
        if (scrolll > 2500) {
          // get-in-touch
          $(".get-in-touch").addClass("ani-show");
        }
      } else if ($(window).width() < 800) {
        if (scrolll > 290) {
          $(".floor").removeClass("bottomHide");
          $(".chair").removeClass("rightHide");
          $(".drawer").removeClass("leftHide");
          $(".vase").removeClass("topHide");
          $(".flower").removeClass("topHide");
          $(".table").removeClass("opacity");
          $(".pc1").removeClass("topHide");
          $(".pc2").removeClass("topHide");
          $(".woman1").removeClass("leftHide");
          $(".woman2").removeClass("rightHide");
        }
      }
    });

    // tl.staggerTo(cards, 0.5, { autoAlpha: 1, y: -20 }, 0.1);
    tl.staggerFromTo(cards, 1, { opacity: 0 }, { opacity: 1 }, 0.15);
    // tl.;
  }

  render() {
    var settings = {
      dots: false,
      arrows: false,
      draggable: false,
    };
    return (
      <div className="home-container">
        <Helmet>
          <title>The College Guidance Co.</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co., university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>

        <Header />
        <div className="banner-container">
          <div className="leftSection">
            <div className="banner-text">About Us</div>
            <div className="banner-desc">
              The College Guidance Co. provides a boutique university guidance
              service, helping students throughout the process, right from
              building a strong profile, to shortlisting universities, essay and
              personal statement writing, putting in a strong application, and
              then selecting which school to finally attend. We also offer
              specialised services for students who need guidance with a
              particular aspect of the university application process.
            </div>
            <div className="banner-button">
              <NavLink to="/contact">
                <Button name="Schedule an Appointment" />
              </NavLink>
            </div>
          </div>
          <div className="rightSection">
            <img
              src={cover1}
              alt=""
              className="cover1 animated fadeInDown animation"
            />
            <img
              src={cover2}
              alt=""
              className="cover2 animated fadeInDown animation"
            />
            <img
              src={cover3}
              alt=""
              className="cover3 animated fadeInDown animation"
            />
            <img
              src={cover4}
              alt=""
              className="cover4 animated fadeInDown animation"
            />
            <img
              src={cover5}
              alt=""
              className="cover5 animated fadeInDown animation"
            />
            <img
              src={cover6}
              alt=""
              className="cover6 animated fadeInDown animation"
            />
            <img
              src={book1}
              alt=""
              className="book1 animated fadeInDown animation"
            />
            <img
              src={book2}
              alt=""
              className="book2 animated fadeInDown animation"
            />
            <img
              src={book3}
              alt=""
              className="book3 animated fadeInDown animation"
            />
            <img
              src={book4}
              alt=""
              className="book4 animated fadeInDown animation"
            />
            <img src={cap} alt="" className="cap animated flipInX animation" />
            <img
              src={degree}
              alt=""
              className="degree animated fadeInDown animation"
            />
            <img
              src={book7}
              alt=""
              className="book7 animated fadeInDown animation"
            />
            <img
              src={book8}
              alt=""
              className="book8 animated fadeInDown animation"
            />
            <img
              src={book9}
              alt=""
              className="book9 animated fadeInDown animation"
            />
            <img
              src={pin}
              alt=""
              className="pin animated slideInDown animation"
            />
          </div>
        </div>
        <div className="about">
          <div className="leftSection">
            <div className="mob-title">The Founders</div>
            <img src={floor} alt="" className="floor bottomHide" />
            <img src={chair} alt="" className="chair rightHide" />
            <img src={drawer} alt="" className="drawer leftHide" />
            <img src={vase} alt="" className="vase topHide" />
            <img src={flower} alt="" className="flower topHide" />
            <img src={table} alt="" className="table opacity" />
            <img src={pc1} alt="" className="pc1 topHide" />
            <img src={pc2} alt="" className="pc2 topHide" />
            <img src={woman1} alt="" className="woman1 leftHide" />
            <img src={woman2} alt="" className="woman2 rightHide" />
          </div>
          <div className="rightSection">
            <div className="about-header home-header">Our Founders</div>
            <div className="founders">
              <div className="founderContainer">
                <p className="name">Alisha Israni</p>
                <p className="post">Founder</p>
                <p className="desc">
                  The College Guidance Co. was founded by Alisha Israni in 2020.
                  Alisha has been a university guidance counselor at B.D. Somani
                  International School for 6 years, out of which she headed the
                  department for 3 years.
                </p>
              </div>
              <div className="founderContainer">
                <p className="name">Alina Israni</p>
                <p className="post">Co-founder</p>
                <p className="desc">
                  Alina Israni worked at BD Somani International School for a
                  year, during which she helped students with their university
                  applications. She is passionate about writing and enjoys
                  guiding students with their essays.
                </p>
              </div>
            </div>
            {/* <div className="about-desc">
              <div className="founders-info">
                <div className="desc">
                  The College Guidance Co. was founded by Alisha Israni in 2020.
                  Alisha has been a university guidance counselor at B.D. Somani
                  International School for 6 years, out of which she headed the
                  department for 3 years.
                </div>
                <p className="name">Alisha Asrani</p>
                <p className="post">Co-founder</p>
              </div>
              <div className="founders-info2">
                <div className="desc">
                  The College Guidance Co. was founded by Alisha Israni in 2020.
                  Alisha has been a university guidance counselor at B.D. Somani
                  International School for 6 years, out of which she headed the
                  department for 3 years.
                </div>
                <p className="name">Alina Asrani</p>
                <p className="post">Co-founder</p>
              </div>
            </div> */}
            <div className="about-button">
              <NavLink
                to="about"
                onClick={() => this.props.btnClick("founders")}
              >
                <Button name="Know More" />
              </NavLink>
            </div>
          </div>
        </div>
        <div className="testimonials">
          <div className="home-header header">Testimonials</div>
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.state.testimonials.map((item, index) => {
              return (
                <Testimonial
                  key={index + 154}
                  logo={item.logo}
                  words={item.words}
                  parentName={item.name}
                  parentOf={item.parent}
                  university={item.university}
                />
              );
            })}
          </Slider>

          <img
            src={arrow}
            alt=""
            className="leftArrow"
            onClick={() => this.prev()}
          />
          <img
            src={arrow}
            alt=""
            className="rightArrow"
            onClick={() => this.next()}
          />
          <div className="testimonial-button">
            <NavLink to="/testimonials">
              <Button name="Read More" />
            </NavLink>
          </div>
        </div>
        <div className="what-we-offer">
          <div className="offer-header home-header">Our Services</div>
          <div className="services">
            {$(window).width() > 800
              ? this.state.services.map((item, index) => {
                  return (
                    <div
                      className="serviceContainer"
                      ref={(div) => (cards[index] = div)}
                      key={index + 335}
                    >
                      <Service
                        image={item.img}
                        text={item.text}
                        className="serviceAdjust"
                      />
                    </div>
                  );
                })
              : this.state.services.map((item, index) => {
                  if (index < 6) {
                    return (
                      <div
                        className="serviceContainer"
                        ref={(div) => (cards[index] = div)}
                        key={index + 95}
                      >
                        <Service
                          image={item.img}
                          text={item.text}
                          className="serviceAdjust"
                        />
                      </div>
                    );
                  } else return null;
                })}
            {/* {this.state.services.map((item, index) => {
              return (
                <div
                  className="serviceContainer"
                  ref={div => (cards[index] = div)}
                >
                  <Service image={item.img} text={item.text} />{" "}
                </div>
              );
            })} */}
            <div className="custom-service opacityHide">
              <NavLink to="/services">
                <Button name="Know More" />
              </NavLink>
            </div>
          </div>
          {/* <div className="consultation">
            <div className="text">
              <p className="sub-header">
                Find Solutions To All Your Career-Related Concerns
              </p>
              <p className="header">Schedule a Free Consultation</p>
            </div>
            <div className="consult-button">
              <NavLink to="/contact">
                <Button
                  name="Book you consultation with the experts"
                  className="adjust-consult-button"
                />
              </NavLink>
            </div>
          </div> */}
        </div>

        <div className="students">
          <div
            class="home-header header"
            style={{ color: "#fff", textAlign: "center" }}
          >
            Universities that our first cohort of students will be attending
            (2020-2021)
          </div>
          <div className="wrapper">
            <img src={insta1} alt="Student1" className="student-img" />
            <img src={insta2} alt="Student2" className="student-img" />
            <img src={insta3} alt="Student3" className="student-img" />
            <img src={insta4} alt="Student4" className="student-img" />
            <img src={insta5} alt="Student5" className="student-img" />
            <img src={insta6} alt="Student6" className="student-img" />
          </div>
        </div>

        <GetInTouch />
        <div className="news-room">
          <div className="home-header news-head">News Room</div>
          <Slider ref={(c) => (this.slider2 = c)} {...settings}>
            {/* <div className="event-1-container">
              <NavLink to="/register/boston">
                <div className="event-section">
                  <img src={event3} alt="" />
                  <p className="home-header">Click to Register</p>
                </div>
              </NavLink>
            </div> */}

            {this.props.news.map((item, index) => {
              return (
                <NewsSlide
                  share={true}
                  header={item.header}
                  link={item.link}
                  image={item.image}
                  description={item.description[0].substring(0, 250)}
                  key={index + 100}
                />
              );
            })}
            <div className="event-1-container">
              <div className="event-section">
                <img src={event3} alt="" />
                <p className="home-header">Registration is closed</p>
              </div>
            </div>
            <div className="event-1-container">
              <div className="event-section">
                <img src={event2} alt="" />
                <p className="home-header">Registration is closed</p>
              </div>
            </div>
            <div className="event-1-container">
              <div className="event-section">
                <img src={event1} alt="" />
                <p className="home-header">Registration is closed</p>
              </div>
            </div>
          </Slider>

          <img
            src={arrow}
            alt=""
            className="leftArrow"
            onClick={() => this.prev2()}
          />
          <img
            src={arrow}
            alt=""
            className="rightArrow"
            onClick={() => this.next2()}
          />
          <div className="swipeMsg">Swipe to Navigate</div>
          {/* <NewsSlide /> */}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextpage: (name) => dispatch({ type: "display_info", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
