import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./newsroom.css";
import $ from "jquery";
import { connect } from "react-redux";
// import news1 from "../../components/images/news1.jpeg";
import event1 from "../../components/images/events/event2.jpeg";
import { Helmet } from "react-helmet";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();
class NewsRoom extends Component {
  state = {};
  componentDidMount() {
    this.props.initNews();
    this.props.updateBlogLink(this.props.activeeNews);

    $(window).scrollTop(0);
    // this.props.btnClick("");
    // this.props.setNewsName(this.props.activeeNews);
    $(".menu-container:nth-child(5)").addClass("activeRoute");
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(this.props.activeBlog, prevProps.activeBlog);
    if (this.props.activeBlog != prevProps.activeBlog) {
      $(document).scrollTop(0);
      this.props.initNews();
      history.push("/news/" + this.props.activeBlog);
      $(".menu-container:nth-child(5)").addClass("activeRoute");
    }
  }

  render() {
    return (
      <div className="newsroom-container">
        <Helmet>
          <title>News Room | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co.,blog,news, university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>

        <Header />
        <div className="data">
          <div className="left-section">
            {this.props.news.map((item, index) => {
              if (this.props.activeBlog === item.link) {
                return (
                  <div className="data-container" key={index + 74}>
                    <img src={item.image} alt="" className="floated" />
                    <div className="text">
                      <div className="header">{item.header}</div>
                      <div className="article-by">
                        Article by : {item.articleBy}
                      </div>
                      {item.description.map((desc, index) => {
                        if (desc === ".") {
                          if ($(window).width() < 800) {
                            return (
                              <p key={index + 37} style={{ display: "none" }}>
                                {desc}
                              </p>
                            );
                          } else {
                            return (
                              <p key={index + 35} style={{ opacity: 0 }}>
                                {desc}
                              </p>
                            );
                          }
                        } else return <p key={index + 34}>{desc}</p>;
                      })}
                      {Object.keys(item.ol).length > 0 && (
                        <ol className="list">
                          {item.ol.map((desc, index) => {
                            return <li key={index + 6}>{desc}</li>;
                          })}
                        </ol>
                      )}

                      {Object.keys(item.description2).length > 0 &&
                        item.description2.map((desc, index) => {
                          return <p key={index + 72}>{desc}</p>;
                        })}
                    </div>
                    <div className="blog-navigation">
                      <div
                        className="prev-blog"
                        onClick={() => this.props.prevBlog()}
                      >
                        <div className="previous-text">PREVIOUS</div>
                        <div className="previous-blog-name">
                          {this.props.prevBlogName
                            .replace(/-/g, " ")
                            .toUpperCase()}
                        </div>
                      </div>
                      <div
                        className="next-blog"
                        onClick={() => this.props.nextBlog()}
                      >
                        <div className="next-text">NEXT</div>
                        <div className="next-blog-name">
                          {this.props.nextBlogName
                            .replace(/-/g, " ")
                            .toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="right-section">
            {/* <div className="header center">Next Blog</div> */}
            {/* <div className="comingSoon">
              <p>Last Event</p>
            </div> */}
            <div className="event">
              <div className="head">
                <p>Last Event</p>
              </div>
              {/* <NavLink to='/register/bentley'> */}
              <img src={event1} alt="" />
              {/* </NavLink> */}
              {/* <NavLink to='/register/bentley'> */}
              {/* <div className="header center">Click to Register</div> */}
              {/* </NavLink> */}
            </div>
            {/* {this.props.news.map((item, index) => {
              if (this.props.nextNews === item.link) {
                return (
                  <NewsSlide
                    header={item.header}
                    link={item.link}
                    image={item.image}
                    description={item.description[0]}
                    className="adjust-newsSlide"
                  />
                );
              }
            })} */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news: state.news,
    activeNews: state.activeNews,
    activeBlog: state.activeBlog,
    nextNews: state.nextNews,
    nextBlogName: state.nextBlogName,
    prevBlogName: state.prevBlogName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewsName: (name) => dispatch({ type: "setNewsName", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
    initNews: () => dispatch({ type: "initNews" }),
    updateBlogLink: (name) =>
      dispatch(dispatch({ type: "setActiveBlog", value: name })),
    setActiveBlog: (name) => dispatch({ type: "setActiveBlog", value: name }),
    nextBlog: (name) => dispatch({ type: "nextBlog", value: name }),
    prevBlog: (name) => dispatch({ type: "prevBlog", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsRoom);
