import React, { Component } from "react";
import "./fixedHeader.css";
import menu from "../images/menu.png";
import logo from "../images/logo.png";
import { NavLink } from "react-router-dom";
import $ from "jquery";
var scroll = 0;
class FixedHeader extends Component {
  state = {};
  componentDidMount() {
    $(window).scroll(function () {
      // var sticky = $(".header-container"),
      scroll = $(window).scrollTop();
      // console.log(scroll);
      if (scroll > 100) {
        $(".fixed-header").css("display", "flex");
        $(".fixed-header").css("position", "fixed");
      } else {
        $(".fixed-header").css("display", "none");
        $(".fixed-header").css("position", "relative");
      }
    });
  }
  render() {
    const menuToggle = flag => {
      if (flag === 1) {
        $(".mob-menu-logo").css("display", "none");
        $(".mob-menu-container").css("display", "flex");
        $("body").css("overflow", "hidden");
      } else {
        $(".mob-menu-logo").css("display", "block");
        $(".mob-menu-container").css("display", "none");
        $("body").css("overflow", "auto");
      }
    };
    return (
      <div className="fixed-header">
        {$(window).width() < 800 && (
          <NavLink to="/" exact>
            <div className="logo-container">
              <img src={logo} alt="" className="logo" />
            </div>
          </NavLink>
        )}
        {/* <NavLink to="/" exact>
          <div className="logo-container">
            <img src={logo} alt="" className="logo" />
          </div>
        </NavLink> */}
        {$(window).width() > 800 && (
          <ul className="menu-container">
            <NavLink activeClassName="activeRoute" to="/" exact>
              <li>HOME</li>
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/about" exact>
              <li>ABOUT</li>
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/services" exact>
              <li>SERVICES</li>
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/testimonials">
              <li>TESTIMONIALS</li>
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/news/summer-break-importance" exact>
              <li>NEWSROOM</li>
            </NavLink>
            <NavLink activeClassName="activeRoute" to="/contact" exact>
              <li>CONTACT</li>
            </NavLink>
          </ul>
        )}

        {$(window).width() < 800 && (
          <div className="mob-menu-logo" onClick={() => menuToggle(1)}>
            <img src={menu} alt="" />
          </div>
        )}
        {$(window).width() > 800 ? (
          ""
        ) : (
            <div className="mob-menu-container">
              <div className="close-icon" onClick={() => menuToggle(0)}>
                Close
            </div>
              <div className="mob-link-container">
                <NavLink to="/" activeClassName="mob-active" exact>
                  <div className="mob-link"> HOME </div>
                </NavLink>
                <NavLink to="/about" activeClassName="mob-active" exact>
                  <div className="mob-link">ABOUT</div>
                </NavLink>
                <NavLink to="/services" activeClassName="mob-active" exact>
                  <div className="mob-link">SERVICES</div>
                </NavLink>
                <NavLink to="/testimonials" activeClassName="mob-active" exact>
                  <div className="mob-link">TESTIMONIALS</div>
                </NavLink>
                <NavLink to="/news/summer-break-importance" activeClassName="mob-active" exact>
                  <div className="mob-link">NEWSROOM</div>
                </NavLink>
                <NavLink to="/contact" activeClassName="mob-active" exact>
                  <div className="mob-link">CONTACT</div>
                </NavLink>
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default FixedHeader;
