import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./register.css";
import $ from "jquery";
import { Helmet } from "react-helmet";

class Register extends Component {
  state = {};
  componentDidMount() {
    $(window).scrollTop(0);
  }

  render() {
    return (
      <div className="register-container">
        <Helmet>
          <title>Register | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co.,blog,news, university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        <div className="form-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSf8Zcp6vrkh59jTJwhh9JudfBRzytpYAG7-61rjmzS4Zmyt5w/viewform?embedded=true"
            width="640"
            height="1125"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Register;
