import React, { Component } from "react";
import "./testimonial.css";

import doublequotes from "../images/doublequotes.png";
class Testimonial extends Component {
  state = {};
  render() {
    return (
      <div className="testimonial-container">
        <div className="words">
          {this.props.words.map((words, index) => {
            return (
              <p key={index + 33} style={{ whiteSpace: "break-spaces" }}>
                {words}
              </p>
            );
          })}
          <img src={doublequotes} alt="" className="rightQuote" />
          <img src={doublequotes} alt="" className="leftQuote" />
        </div>
        <div className="student-data">
          <div className="parent-info">
            <div className="parent-name">{this.props.parentName}</div>
            <div className="parent-of">{this.props.parentOf}</div>
            <div className="university-name">{this.props.university}</div>
          </div>
          <div className="university-logo">
            <img src={this.props.logo} alt="" />
          </div>
        </div>
        {/* <div className="testimonial-button">
          <NavLink to="/testimonials">
            <Button name="Read More" />
          </NavLink>
        </div> */}
      </div>
    );
  }
}

export default Testimonial;
