import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import './pagenotfound.css'

class PageNotFound extends Component {
    state = {}
    render() {
        return (<div className="page-not-found-container">
            <Header />
            <div className="msg-container">
                <p className="error-header">ERROR 404</p>
                <p className="error-msg">
                    We're sorry, the page you requested could not be found. Please go back to the homepage or contact us at <a href="mailto:alisha@thecollegeguidanceco.com">alisha@thecollegeguidanceco.com</a>
                </p>
            </div>
            <Footer />
        </div>);
    }
}

export default PageNotFound;