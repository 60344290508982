import React, { Component } from "react";
import "./newsslide.css";
// import news1 from "../images/newsroom/news1.png";
import email from "../images/email2.png";
import facebook from "../images/facebook-logo.png";
import { FacebookShareButton } from "react-share";
import { NavLink } from "react-router-dom";

class NewsSlide extends Component {
  state = {};
  render() {
    return (
      <NavLink to={"/news/" + this.props.link}>
        <div
          className={
            this.props.className
              ? "newsSlider-container " + this.props.className
              : "newsSlider-container"
          }
        >
          <div className="newsSlider">
            <div className="image-section">
              <img src={this.props.image} alt="" />
            </div>
            <div className="news-header">{this.props.header}</div>
            <div className="news-description">{this.props.description}...</div>

            <div className="readMore">Read More...</div>

            {this.props.share && (
              <div className="share">
                <p>Share :</p>
                {/* <a href="mailto:alisha@cgc.com" target="_top"> */}
                <img src={email} alt="" className="email" />
                {/* </a> */}
                <FacebookShareButton
                  className="icon"
                  children=""
                  // quote={this.props.quote}
                  url={
                    "https://www.thecollegeguidanceco.com//news/" +
                    this.props.link
                  }
                >
                  <img src={facebook} alt="" className="facebook" />
                </FacebookShareButton>
              </div>
            )}
          </div>
        </div>
      </NavLink>
    );
  }
}

export default NewsSlide;
