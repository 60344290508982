import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Button from "../../components/button/Button";
import phone from "../../components/images/phone.png";
import email from "../../components/images/email.png";
// import address from "../../components/images/address.png";
import insta from "../../components/images/insta.png";
import fb from "../../components/images/fb.png";

import background from "../../components/images/contact-image/background.png";
import efront from "../../components/images/contact-image/efront.png";
import eback from "../../components/images/contact-image/eback.png";
import eside from "../../components/images/contact-image/eside.png";
import letter from "../../components/images/contact-image/letter.png";
import women3 from "../../components/images/contact-image/women3.png";
import chatBubble from "../../components/images/contact-image/chat-bubble.png";
import telephone from "../../components/images/contact-image/telephone.png";
import bubble1 from "../../components/images/contact-image/bubble1.png";
import bubble2 from "../../components/images/contact-image/bubble2.png";
import bubble3 from "../../components/images/contact-image/bubble3.png";
import bubble4 from "../../components/images/contact-image/bubble4.png";
import string from "../../components/images/contact-image/strings.png";
import leaf1 from "../../components/images/contact-image/leaf1.png";
import leaf2 from "../../components/images/contact-image/leaf2.png";
import floor from "../../components/images/contact-image/floor.png";
import isign from "../../components/images/contact-image/i-sign.png";
import atsign from "../../components/images/contact-image/at-sign.png";
import cup from "../../components/images/contact-image/cup.png";
import { Helmet } from "react-helmet";
import "./contact.css";

class Contact extends Component {
  state = {
    Name: "",
    Mobile: "",
    Email: "",
    ispop: false,
    message: "",
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange2 = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ Mobile: onlyNums });
  };
  sendData = (name, mobile, email) => {
    if (email === "") {
      $(".message-box").addClass("error");
      return this.setState({ message: "Email is Required" });
    }
    if (
      email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        to: "alisha@thecollegeguidanceco.com",
        subject: "Enquiry from website",
        message:
          "Name: " +
          name +
          "\n" +
          "Number: " +
          mobile +
          "\n" +
          "Email: " +
          email,
      });

      var requestOptions = {
        method: "POST",
        mode: "no-cors",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://lyerrn04vd.execute-api.ap-south-1.amazonaws.com/default/mailing_on_post",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          this.setState({
            message: "Message Sent",
            Email: "",
            Name: "",
            Mobile: "",
          });
        })
        .catch((error) => console.log("error", error));
    } else {
      $(".message-box").addClass("error");
      this.setState({ message: "Enter Valid Email" });
    }
  };
  componentDidMount() {
    $(window).scrollTop(0);
    setTimeout(function () {
      $(".eback").removeClass("topHide");
      $(".eside").removeClass("topHide");
      $(".efront").removeClass("topHide");
      $(".letter").removeClass("adjust-topHide");
      $(".women3").removeClass("rightHide");
      $(".telephone").removeClass("leftHide");

      $(".flower").removeClass("topHide");
      $(".table").removeClass("opacity");
      $(".pc1").removeClass("topHide");
      $(".pc2").removeClass("topHide");
      $(".woman1").removeClass("leftHide");
    }, 10);
    this.props.btnClick("");
  }
  render() {
    return (
      <div className="contact-container">
        <Helmet>
          <title>Our Services | TheCollegeGuidanceco.com</title>
          <meta
            name="description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta
            name="keywords"
            content="The College Guidance Co., university guidance service, helping students, building strong profile,shortlisting universities, essay, personal statement writing, strong application, selecting school,contact"
          />
          <link rel="canonical" href="thecollegeguidanceco.com/" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="The College Guidance Co." />
          <meta property="og:url" content=" thecollegeguidanceco.com" />
          <meta
            property="og:description"
            content="The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content=" @collegeguidance" />
          <meta name="twitter:title" content="The College Guidance Co." />
          <meta
            name="twitter:description"
            content=" The College Guidance Co. provides a boutique university guidance service, helping students throughout the process, right from building a strong profile, to shortlisting universities, essay and personal statement writing, putting in a strong application, and then selecting which school to finally attend."
          />
        </Helmet>
        <Header />
        <div className="middle">
          <div className="left">
            <p className="head">CONTACT US</p>
            <div className="full-contact">
              <div className="call">
                <img src={phone} alt="" />
                <p>
                  <a href="tel:+91 9820511550">+91 9820511550</a>
                </p>
                <p className="divider">/</p>
                <p>
                  <a href="tel:+91 9819711660">+91 9819711660</a>
                </p>
              </div>
              <div className="email">
                <img src={email} alt="" />
                <a href="mailto:alisha@thecollegeguidanceco.com">
                  <p>alisha@thecollegeguidanceco.com</p>
                  <br />
                </a>
                <a href="mailto:alina@thecollegeguidanceco.com">
                  <p>alina@thecollegeguidanceco.com</p>
                </a>
              </div>
              {/* <div className="address">
                <img src={address} alt="" />
                <p>
                  Office no 201,
                  <br />
                  TRADE Avenue, Suren Rd,
                  <br />
                  Chakala, Andheri East,
                  <br />
                  Mumbai, Maharashtra 400093
                  <br />
                  <br />
                  Near western Express highway metro Station
                </p>
              </div> */}
              <div className="buttons">
                {/* <Button className="button" name="Locate Us" /> */}
                <Button
                  className="button"
                  name="Leave Us A Message"
                  onClick={() => this.setState({ ispop: !this.state.ispop })}
                />
              </div>
            </div>
          </div>
          <div className="right">
            <img
              src={background}
              alt=""
              className="background animated fadeIn animation"
            />
            <img src={eback} alt="" className="eback topHide" />
            <img src={eside} alt="" className="eside topHide" />
            <img src={efront} alt="" className="efront topHide" />
            <img src={letter} alt="" className="letter adjust-topHide" />
            <img src={women3} alt="" className="women3 rightHide" />
            <img
              src={chatBubble}
              alt=""
              className="chatBubble animated fadeInDown animation"
            />
            <img src={women3} alt="" className="women3 rightHide" />
            <img
              src={telephone}
              alt=""
              className="telephone leftHide animated heartBeat animation"
            />
            <img
              src={bubble1}
              alt=""
              className="bubble1 animated fadeIn animation"
            />
            <img
              src={bubble2}
              alt=""
              className="bubble2 animated fadeInDown animation"
            />
            <img
              src={bubble3}
              alt=""
              className="bubble3 animated fadeInDown animation"
            />
            <img
              src={bubble4}
              alt=""
              className="bubble4 animated fadeInDown animation"
            />
            <img
              src={string}
              alt=""
              className="string animated fadeInDown animation"
            />
            <img
              src={leaf1}
              alt=""
              className="leaf1 animated fadeInDown animation"
            />
            <img
              src={leaf2}
              alt=""
              className="leaf2 animated fadeInDown animation"
            />
            <img
              src={floor}
              alt=""
              className="floor animated fadeIn animation"
            />
            <img
              src={isign}
              alt=""
              className="isign animated zoomIn animation"
            />
            <img
              src={atsign}
              alt=""
              className="atsign animated rollIn animation"
            />
            <img src={cup} alt="" className="cup animated zoomIn animation" />

            <a href="https://www.facebook.com/The-College-Guidance-Co-103283531366972">
              <img src={fb} alt="" className="fb animated zoomIn animation" />
            </a>
            <a href="https://www.instagram.com/thecollegeguidanceco/">
              <img
                src={insta}
                alt=""
                className="tweet animated zoomIn animation"
              />
            </a>
          </div>
        </div>
        {this.state.ispop && (
          <div className="popup ani-show">
            <div
              className="overlay"
              onClick={() => this.setState({ ispop: !this.state.ispop })}
            />
            <div className="popup-container ">
              <p
                className="close"
                onClick={() => this.setState({ ispop: !this.state.ispop })}
              >
                X
              </p>
              <div className="header">Leave Us a Message</div>
              <div className="input-container">
                <input
                  type="text"
                  value={this.state.Name}
                  name="Name"
                  onChange={this.handleChange}
                  placeholder="Name"
                />
                <input
                  type="text"
                  value={this.state.Mobile}
                  name="Mobile"
                  onChange={this.handleChange2}
                  placeholder="Mobile Number"
                />
                <input
                  type="text"
                  value={this.state.Email}
                  name="Email"
                  onChange={this.handleChange}
                  placeholder="Email Address"
                />
                <div className="message">{this.state.message}</div>
                <div
                  className="send"
                  onClick={() =>
                    this.sendData(
                      this.state.Name,
                      this.state.Mobile,
                      this.state.Email
                    )
                  }
                >
                  Send
                </div>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    news: state.news,
    activeNews: state.activeNews,
    nextNews: state.nextNews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewsName: (name) => dispatch({ type: "setNewsName", value: name }),
    btnClick: (name) => dispatch({ type: "btnClick", value: name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
