import React, { Component } from "react";
import "./button.css";

class Button extends Component {
  state = {};

  render() {
    return (
      <div
        className={
          this.props.className
            ? "button-container " + this.props.className
            : "button-container"
        }
        onClick={this.props.onClick}
      >
        <p>{this.props.name}</p>
      </div>
    );
  }
}

export default Button;
