import React, { Component } from "react";
import "./getintouch.css";
import image from "../images/form/image.png";
import box from "../../components/images/form/box.png";
import send from "../../components/images/form/send.png";
import $ from "jquery";
var scrolll = 0;
class GetInTouch extends Component {
  state = {
    message: "Get In Touch",
    Name: "",
    Mobile: "",
    Email: "",
    showMessage: true,
    // activeMobile: false,
  };
  componentDidMount() {
    $(window).scroll(function () {
      scrolll = $(window).scrollTop();

      if ($(window).width() > 800) {
        if (scrolll > 2500) {
          // get-in-touch
          $(".get-in-touch").addClass("ani-show");
        }
      } else if ($(window).width() < 800) {
        if (scrolll > 290) {
        }
      }
    });
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleChange2 = (e) => {
    if (isNaN(e.target.value)) {
      return;
    }
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ Mobile: onlyNums });
  };
  //   isValidEmailAddress(address, e) {
  //     if (address === "") {
  //       $(".emailInvalid").css("display", "block");
  //       emailIsValid = false;
  //     }
  //     if (
  //       address.match(
  //         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //       )
  //     ) {
  //       $(".emailInvalid").css("display", "none");
  //       emailIsValid = true;
  //     } else {
  //       $(".emailInvalid").css("display", "block");
  //       emailIsValid = false;
  //       return this.setState({ emailIsValid: false });
  //     }
  //   }

  imageClick = () => {
    this.setState({ showMessage: false });
    if ($(window).width() > 800) {
      $(".image-container").addClass("adjust-left");
      $(".box").addClass("adjust-right");
      $(".send").removeClass("adjust-right");
      this.setState({ message: "Enter Your Name" });
      $(".input1").removeClass("topHide");
    } else {
      $(".image-container").addClass("adjust-left");
      $(".message-box").addClass("adjust-mob-msgBox ");
      $(".box").addClass("adjust-mob-box");
      $(".send").removeClass("adjust-left2");
      this.setState({ message: "Enter Your Name" });
      $(".input1").removeClass("topHide");
      $(".send").css("display", "block");
    }
  };
  setData2 = () => {};
  setName = () => {
    if (this.state.Name === "") {
      $(".message-box").addClass("error");
      this.setState({ message: "Name is Required" });
    }
    if (this.state.Name && !this.state.Mobile) {
      $(".input2").removeClass("topHide");
      $(".input1").addClass("topHide");
      $(".message-box").removeClass("ani-show");
      $(".message-box").addClass("ani-show");
      this.setState({ message: "Enter Your Mobile No." });
      $(".send").css("display", "none");
      $(".send").addClass("adjust-right");
      $(".send2").css("display", "block");
      $(".message-box").removeClass("error");
    }
    // if (this.state.Mobile && !this.state.Email) {
    //   $(".input3").removeClass("topHide");
    //   $(".input2").addClass("topHide");
    //   this.setState({ message: "Enter Your Email" });
    // }
  };
  setMobile = () => {
    if (this.state.Mobile === "") {
      $(".message-box").addClass("error");
      this.setState({ message: "Mobile No is Required" });
    }
    if (this.state.Mobile && !this.state.Email) {
      $(".input3").removeClass("topHide");
      $(".input2").addClass("topHide");
      $(".message-box").removeClass("error");
      this.setState({ message: "Enter Your Email" });
      $(".send").css("display", "none");
      $(".send2").css("display", "none");
      $(".send3").css("display", "block");
    }
  };
  sendData = (name, mobile, email) => {
    var myHeaders = new Headers();
    var raw;
    var requestOptions = {};
    if (email === "") {
      $(".message-box").addClass("error");
      return this.setState({ message: "Email is Required" });
    }
    if (
      email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      if ($(window).width() > 800) {
        myHeaders.append("Content-Type", "application/json");
        raw = JSON.stringify({
          to: "alisha@thecollegeguidanceco.com",
          subject: "Enquiry from website",
          message:
            "Name: " +
            name +
            "\n" +
            "Number: " +
            mobile +
            "\n" +
            "Email: " +
            email,
        });

        requestOptions = {
          method: "POST",
          mode: "no-cors",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://lyerrn04vd.execute-api.ap-south-1.amazonaws.com/default/mailing_on_post",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            $(".message-box").removeClass("error");
            $(".image-container").removeClass("adjust-left");
            $(".box").removeClass("adjust-right");
            $(".input3").addClass("topHide");
            $(".send").css("display", "block");
            $(".send3").css("display", "none");
            this.setState({
              message: "Message Sent",
              Email: "",
              Name: "",
              Mobile: "",
              showMessage: true,
            });
          })
          .catch((error) => console.log("error", error));
      } else {
        myHeaders.append("Content-Type", "application/json");
        raw = JSON.stringify({
          to: "alisha@thecollegeguidanceco.com",
          subject: "Enquiry from website",
          message:
            "Name: " +
            name +
            "\n" +
            "Number: " +
            mobile +
            "\n" +
            "Email: " +
            email,
        });

        requestOptions = {
          method: "POST",
          mode: "no-cors",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://lyerrn04vd.execute-api.ap-south-1.amazonaws.com/default/mailing_on_post",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            $(".message-box").removeClass("error");
            $(".image-container").removeClass("adjust-left");
            $(".box").removeClass("adjust-mob-box");
            $(".input3").addClass("topHide");
            $(".send3").css("display", "none");
            this.setState({
              message: "Message Sent",
              Email: "",
              Name: "",
              Mobile: "",
              showMessage: true,
            });
          })
          .catch((error) => console.log("error", error));
      }
    } else {
      $(".message-box").addClass("error");
      this.setState({ message: "Enter Valid Email" });
    }
    // if (this.state.Mobile && !this.state.Email) {
    //   $(".input3").removeClass("topHide");
    //   $(".input2").addClass("topHide");
    //   $(".message-box").removeClass("error");
    //   this.setState({ message: "Enter Your Email" });
    // }
  };
  // sendData = () => {

  // };
  render() {
    return (
      <div className="get-in-touch">
        <div
          className="image-container"
          onClick={() => {
            this.state.message === "Get In Touch"
              ? this.imageClick()
              : this.setData2();
          }}
        >
          <img src={image} alt="" className="image" />
          <div className="message-box ani-show">{this.state.message}</div>
        </div>
        <img
          src={send}
          alt=""
          className={
            $(window).width() > 800 ? "send adjust-right" : "send adjust-left2"
          }
          onClick={() => this.setName()}
        />
        <img
          src={send}
          alt=""
          className="send2"
          onClick={() => this.setMobile()}
        />
        <div
          className="send3"
          onClick={() =>
            this.sendData(this.state.Name, this.state.Mobile, this.state.Email)
          }
        >
          Send
        </div>
        <img
          src={box}
          alt=""
          className="box"
          onClick={() => (this.state.Name ? null : this.imageClick())}
        />

        <div className="input-container">
          <input
            className="input1 topHide"
            type="text"
            value={this.state.Name}
            name="Name"
            onChange={this.handleChange}
            placeholder="Name"
          />

          {this.state.Name && (
            <input
              className="input2 topHide"
              type="text"
              value={this.state.Mobile}
              name="Mobile"
              onChange={this.handleChange2}
              placeholder="Mobile No"
            />
          )}
          {this.state.Mobile && (
            <input
              className="input3 topHide"
              type="text"
              value={this.state.Email}
              name="Email"
              onChange={this.handleChange}
              placeholder="Email"
            />
          )}
        </div>

        {this.state.showMessage && (
          <div className="message">Click to open form</div>
        )}
      </div>
    );
  }
}

export default GetInTouch;
