//import next from "../jsx/Animation";
import news1 from "../images/newsroom/news1.png";
// import news2 from "../images/newsroom/news2.png";
import news3 from "../images/newsroom/news3.svg";
import news4 from "../images/newsroom/news4.png";
import news5 from "../images/newsroom/news5.jpg";
const news = [
  {
    header: "How to stay productive and build your profile from home",
    link: "develop-profile-from-home",
    image: news5,
    articleBy: "Alina Israni",
    description: [
      "The pandemic has gone on much longer than we would have anticipated or hoped, forcing many high school students to change their plans of gaining work experience, pursuing extra-curricular activities, or carrying out in-person community service. The bright side, however, is that many of us are now comfortable using technology to try and achieve those goals, to the best of our ability. Here are some ideas to help you stay productive and strengthen your profile from home –",
      ".",
      ".",
      ".",
      ".",
      ".",
    ],
    ol: [
      "Utilise platforms such as blogs and websites to share your interests, experiences and give a voice to the causes you believe in. Not only will this help you interact with others who share the same interests, it will also give you a platform to spread awareness, raise funds for charity and exchange perspectives and ideas.",
      "Form virtual clubs/ organisations with your friends, family and others that you have a hobby or passion in common with. Meet weekly or as convenient to have talks and debates; plan activities that you can do individually and then discuss in meetings. You can even bring in guest speakers to mix things up! For example – virtual book club, biology club.",
      "Use this time to work on a portfolio you might require/ utilise for your college applications. Be it in the field of music, art, design, theatre or anything else you are interested in, this is a great opportunity to devote time to make your portfolio stand out.",
      "Take time out to pursue activities that you can practice individually. If you enjoy football, use this time to perfect the skills that you can on your own. You can even take up art, music, or writing – any interest you have that can be pursued solitarily. With the plethora of resources available online today, this an extremely viable option.",
      "Find an online course that excites you and pursue it diligently. Websites like Coursera have a vast array of courses which ensure that you will find many options that you find interesting. You can even try to master a new language!",
      "Try to find online internships or volunteer virtually. There are many companies and organisations that are looking for interns that can work from home. The organisations will provide you with tasks that can all be completed from the safety of your home. This is a great way to learn new things and expose yourself to different career options, without actually having to take any risk.",
      "Read, read read! – With the abundance of online resources available, do not forget to read good books. It is often from books that we gain our most profound learnings. Besides, reading will also help you write strong essays – an extremely important aspect of university applications!",
      "Research and Reports – you can use this time to research topics or phenomena you find interesting and make your own reports. Knowing how to write academic reports is a very useful skill for the future as well. This research will keep you productively occupied in your free time.",
      "Begin preparing for standardised testing such as the SAT and ACT. You can use books as well as online resources to do so.",
      "Maintain a diary in which you pen down your reflections and thoughts – this will help you at the time of writing your college essays and putting together your resume.",
      "Help around your home, help local communities – often, we learn the most when we’re not trying to actively chase any goals. Helping out those around you and performing small acts of kindness are extremely important in times like these, and will give you a nuanced understanding of yourself and others. You could teach your house help English, assist your mother in the kitchen, or start a small baking business from home. Experiences such as these go a long way in making your perspective more mature.",
    ],
    description2: [],
  },
  {
    header: "How to create a college list that is perfect for YOU!",
    link: "perfect-college-list",
    image: news4,
    articleBy: "Alina Israni",
    description: [
      "With application deadlines around the corner, the time for firming up colleges has finally arrived for high school seniors! While doing this amount of research can be a daunting process, it is also an extremely important one because where you choose to apply will probably end up being where you spend the next 3-5 years of your life!",
      "However, if approached in an organized and thorough manner, you can actually make this process fun, whilst ensuring that your college list is well researched, balanced and most importantly a great fit for YOU!",
      "One common misconception is to blindly follow rankings and rank tables. Even though they may give you a wider sense of where a college is placed, a lot of times you will find that rankings change from year to year and differ amongst different surveyors. More often than not, rankings could depend on factors such as the number of students who go on to do a PhD, which hardly have an impact on your undergraduate experience. Therefore, it is important to keep in mind that while you could use rank tables to broadly categorize universities, it is not necessary that a university ranked at #18 will provide you with a better experience than one ranked #29.",
      "This probably leads you to wonder, “What should I be looking at when finalizing my college list?”. To answer this simply I would advise you to focus on ‘fit’ more than anything else. And what we mean by fit, is, how does this college provide you with the opportunities and resources that best suit your needs, aspirations and goals?",
      "Fit can be determined using a wide range of criteria. Then again, different criteria could pose different levels of importance for different students. For example, if you are a squash player, then squash facilities could be one of your top priorities whereas if you are really interested in learning how to ski, going to a college that has close access to mountains would be important to you! Similarly, if you perform much better in small classes then ensuring smaller class sizes could be a very important factor for you.",
      "Regardless of which countries you are applying to, doing research on the criteria listed below will allow you to ensure that the colleges you apply to are the right fit for you and will give you what you are looking for. ",
    ],
    ol: [
      "Course - Does your university provide you with the course that you want to study? If you are still undecided or want to study an amalgamation of different academic areas, does your college offer you any flexibility?",
      "Entry Requirements – Are you fulfilling the entry requirements for the university/course? Is your list well balanced with enough Dream, Target and Safety schools?",
      "Location – Would you rather be on a traditional campus in a suburban setting or in a college town, or would you rather be in a big city?",
      "Weather- Does climate matter? Would you rather be somewhere warm, moderate or very cold?",
      "University/Class Size – Do you perform better in smaller learning environments, or would you rather be in a much larger university with lots of students? You could also opt for something mid-sized.",
      "Student Diversity – Is being around lots of international students from all around the world important to you?",
      "Cost of Education & Living – Does the overall expense required for the university align with the amount you are willing to spend?",
      "Scholarships Available – How important are scholarships for you? Does the university offer any that you might be able to avail of?",
      "Study Abroad – Do you want to spend some time in another city/country other than where your university is located? Does the university offer study abroad programs or semesters abroad that you find exciting?",
      "Internship Opportunities – Does the university place students at internships or do they offer any co-op programs?",
      "Research – As an undergraduate student, will you have adequate opportunities for research?",
      "Course related facilities available – For example, if you are interested specifically in the natural sciences, what sort of lab facilities are offered by the university?",
      "Extracurricular Activities – Outside of the classroom, does this university offer you the activities that you are passionate about? This could be anything - Music, Sports, Debate, Theatre, Community Service etc.",
      "Personal Criteria – Any other criteria that are important to you/ without which your college experience would not be as fulfilling!",
    ],
    description2: [
      "Please keep in mind that you can change the order of these criteria in any way that appeals to you- keeping the important criteria right at the top and ending with the ones that you find have the smallest impact on your learning and university life. Also note that not every college will offer each and everything that you are looking for, so you will also need to be flexible to some extent whilst short-listing, in order to have a balanced list.",
      "We hope you found these guidelines helpful and that you end up with a comprehensive college list that you are very happy with! If you have any further questions, feel free to get in touch!",
    ],
  },
  {
    header:
      "US University Application Policies - Early Decision, Early Action, Regular Decision and Rolling Admissions explained!",
    link: "us-university-application-policies",
    image: news3,
    articleBy: "Alisha Israni",
    description: [
      "Early Decision (ED) - With application deadlines around 15th October to 1st November, this is one of the earliest rounds a student may apply in. It’s important to note that if admitted through ED, the student is binding themselves to the particular university. This means that you must then attend the particular university and withdraw all other applications, not just from US universities but anywhere in the world. A student can only apply to ONE university ED.",
      "Strategically, ED applications can be beneficial as universities tend to fill a lot of their classes through this round. It also lets them know that they are your top choice and a guaranteed enrol, if admitted. When applying through ED, students hear back from universities by mid December. Many US universities offer ED.",
      "Early Action (EA) - With similar deadlines as ED, EA applications show universities that you are keen to attend them, and prepared with your application for the first round. However, as EA applications are not binding, they are less beneficial than ED strategically. Universities know you are not a guaranteed enrol. A student may apply to as many universities EA as he/ she likes, provided the university offers this policy. Universities reveal admissions decisions for the EA round between mid December and February. Many US universities offer EA.",
      "ED differs from EA in that it constitutes a binding commitment to enrol; that is, if offered admission under an ED program the student MUST withdraw all other applications to other institutions and enrol at that institution. EA is not binding, so a student admitted to a school EA could choose not to enroll into that school. Students can also choose to apply ED to 1 college along with EA to others unless any universities specifically restrict this (Restrictive Early Action). University policies will be available on their individual websites.",
      "Early Decision 2 – With the same policies as ED, the deadline for this round is usually early January. This is a great opportunity for a student who did not get admitted into their first choice college in the ED round and has a strong second preference, that he/she is happy to bind themselves to. This could also be a policy that students can use if they are not ready with their applications by the ED deadlines or feel that they could use the few extra months to strengthen certain aspects of their application. A student may apply through ED2 if he/ she has not applied anywhere ED OR has been denied admission through ED.",
      "Some universities offer ED2, however, we are seeing that with time, it is becoming increasingly popular. ",
      "Regular Decision (RD) - With a deadline of early January (in most cases), regular decision is an application round that is not binding. Almost all universities offer this admission cycle.",
      "Rolling Admission - Some universities offer a relatively long window known as Rolling Admission. This means that students applications are reviewed as and when they apply, and different students will hear their admission decisions at different times. There is no fixed date when decisions are out for all students, however, it is advantageous to apply at the beginning of the window as admissions might get more competitive as seats start to fill up!",
      "A university could offer ED, EA, ED2 as well as RD applications, or might offer a combination of these cycles! While there are advantages to making the most of the Early opportunities, students should carefully assess which round is most beneficial in their individual circumstances! The benefits of applying Early also differ from college to college as they each have their own internal admissions policies and ways of reviewing applications. WHERE you apply early is as important (if not more!) than simply deciding whether you will be applying ED/EA/ED2. Therefore it is integral that students make wise and informed choices when making these decisions. Remember, that there is no blanket rule about how to go about this process as there are several subjective factors that come into play, so think carefully and make the decisions that best suit YOU. All the best!",
    ],
    description2: [],
    ol: [],
  },
  {
    header: "The importance of a productively spent summer break",
    link: "summer-break-importance",
    image: news1,
    articleBy: "Alisha Israni",
    description: [
      "Being high school students, we often feel that the holidays are a well deserved break - and rightly so! However, some advance planning and time spent productively over the summer can actually help you go a long way as far as your university applications are concerned.",
      "Besides helping you develop as a Well rounded individual, Taking on a passion project or pursuing an interesting extracurricular activity will provide you with interesting  ideas and insight for your university application essays. It also depicts to universities that you have many more facets to your personality than just academics! Examples of such activities include - an internship, independent research, summer school and community service.",
      "Especially in the case of universities in the US - it is important that you offer more than just a strong transcript, showing them your personality, creativity, dedication and drive beyond the classroom.",
      "In the case of the UK, a focused activity in the field that you hope to pursue could take you a long way, forming interesting material for your personal statement. It reiterates to universities your interest in the academic area you are applying for.",
      "The summer break is an excellent opportunity to achieve goals that you feel you do not have enough free time for during working school days - so please make the most of it! Be sure to also catch up on academic work so that you get a head start when school reopense.",
      "Use your holidays wisely, and give yourself an opportunity to produce the best application you can!",
      "Good Luck!",
    ],
    description2: [],
    ol: [],
  },
];

const initialState = {
  activeProject: "",
  news: news,
  activeNews: "",
  newsLinkArray: [],
  idd: 0,
  nextNews: "",
  id: "",
  activeBlog: "",
  blogName: [],
  idd2: 0,
  nextBlogName: "",
  prevBlogName: "",
};
const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const putNewsLinkInArray = () => {
    newState.newsLinkArray = [];
    news.map((item) => {
      return newState.newsLinkArray.push(item.link);
    });
  };
  const nextNews = () => {
    newState.newsLinkArray.map((item, index) => {
      if (newState.activeNews === item) {
        return (newState.idd = index);
      } else return "";
    });
    if (newState.idd === newState.newsLinkArray.length - 1) {
      newState.nextNews = newState.newsLinkArray[0];
    } else newState.nextNews = newState.newsLinkArray[newState.idd + 1];
  };
  const SetblogName = () => {
    newState.blogName = [];
    news.map((item) => {
      return newState.blogName.push(item.link);
    });
  };
  const changeActiveBlog = () => {
    newState.blogName.map((item, index) => {
      if (item === newState.activeBlog) {
        newState.idd2 = index;
      }
      return "";
    });
  };

  switch (action.type) {
    case "setNewsName": {
      newState.activeNews = action.value;
      putNewsLinkInArray();
      nextNews();
      break;
    }
    case "btnClick": {
      newState.id = action.value;
      break;
    }
    case "initNews": {
      SetblogName();
      changeActiveBlog();
      if (newState.idd2 === newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[0];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      if (newState.idd2 !== 0 && newState.idd2 < newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.idd2 - 1];
      }
      if (newState.idd2 === 0) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 1];
      }

      break;
    }
    case "setActiveBlog": {
      SetblogName();
      newState.activeBlog = action.value;
      break;
    }
    case "nextBlog": {
      newState.activeBlog =
        newState.blogName[(newState.idd2 + 1) % newState.blogName.length];
      changeActiveBlog();

      if (newState.idd2 === newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[0];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      if (newState.idd2 !== 0 && newState.idd2 < newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.idd2 - 1];
      }
      if (newState.idd2 === 0) {
        newState.nextBlogName = newState.blogName[newState.idd2 + 1];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 1];
      }

      break;
    }
    case "prevBlog": {
      newState.activeBlog =
        newState.blogName[
          (newState.blogName.length + newState.idd2 - 1) %
            newState.blogName.length
        ];
      changeActiveBlog();

      if (newState.idd2 === newState.blogName.length - 1) {
        newState.nextBlogName = newState.blogName[0];
        newState.prevBlogName = newState.blogName[newState.blogName.length - 2];
      }
      break;
    }
    default: {
    }
  }
  return newState;
};

export default reducer;
